import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRoute,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { cloneDeep } from "lodash";
import { Observable, of, combineLatest, debounceTime, take } from 'rxjs';
import { UtilityService } from "src/app/core/services/utility.service";
import { FerrariState } from "../../shared/application-interfaces";
import { setAvailableCarsList, setCarPackage, setSelectedCar, updateConfigLevel } from "../../state/actions";

@Injectable({
  providedIn: "root",
})
export class CarListResolver implements Resolve<any> {
  constructor(
    private util: UtilityService,
    private route: ActivatedRoute,
    private store: Store<FerrariState>
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    console.log("[RESOLVER] CarListResolver called in " + route);
    
    // this.util.loadCars().subscribe(cars => {
    //   this.store.dispatch(setAvailableCarsList({ availableCarsList: cars }));
    // });
    this.util.loadAvailableCarsList().subscribe(cars => {
      this.store.dispatch(setAvailableCarsList({ availableCarsList: cars }));
    });
    // if (!!car$) {
    //   return car$;
    // }
    return of("NO DATA");
  }
}
